import { Accordion, useThemeTokens } from "@alphasights/alphadesign-components";
import styled from "@xstyled/styled-components";

const AccordionItemContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const StyledAccordion = styled(Accordion)(() => {
  const { color, spacing } = useThemeTokens();
  return {
    "> li": {
      border: "none",
      paddingTop: spacing.inner.base04,
      "> :first-child": {
        paddingBlock: 0,
        "> :first-child": {
          paddingBottom: spacing.inner.base02,
        },
        "> :last-child": {
          color: color.text.secondary,
        },
      },
      "> :last-child": {
        paddingBottom: spacing.inner.base02,
      },
    },
    minWidth: "100%",
  };
});

export { AccordionItemContentsWrapper, StyledAccordion };
