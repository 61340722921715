import { useEffect, useMemo, useState } from "react";
import * as React from "react";
import { Pill, Tooltip, Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { getStateColorFlyout, getStateName, getSurveyStateColorFlyout } from "components/AdvisorCard/stateFunctions";
import { CidStatus } from "components/CidBadge/cid-status";
import {
  Globe,
  AlphaCircle,
  Recommended,
  Angle,
  Lock,
  Interpreted,
  Credit,
  SeniorExecutive,
} from "@alphasights/alphadesign-icons";
import { x } from "@xstyled/styled-components";
import { useInteractionHeaderStyles } from "./InteractionHeader.styles";
import { InfoBanner } from "components/Banner";
import { FormattedDateTime } from "providers/TimezoneProvider";
import { canCreateResearchRequest } from "components/PastProjectsBanner";
import { Badge } from "components/Badge";
import { PORTAL_WORKSTREAM_BADGE, ProjectBadged, useProjectBadgeContext } from "providers/BadgeProvider";
import { InteractionActionIcons } from "../Topbar/InteractionActionIcons";
import { HighlightTextTypography } from "components/AdvisorCard/HighlightText";
import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { getLanguageFromCode } from "helpers/displayHelpers";
import { CtaButton } from "../Topbar/Topbar";
import { useHideExpertContext } from "../../../../providers/HideExpertProvider";
import { isPotentialSurveyResponse, shouldShowTransId } from "components/SurveysPage/helpers";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { getWorkstream } from "components/InteractionsPage/helpers/Workstreams";
import { TooltipStateDetail } from "components/InteractionsPage/TooltipStateDetail";
import { InteractionLabel } from "components/InteractionLabel/InteractionLabel";
import { useStarExpertContext } from "providers/StarExpertProvider";
import { InteractionsService } from "services/interactionsService";
import { InteractionWithOtherCountsAndStates } from "models/ExpertTable";
import { HitOrigin, ProjectFeature } from "@alphasights/portal-api-client";
import tokens from "@alphasights/alphadesign-tokens/dist/js/portal/tokens";
import { useExecutivePartnershipsEligibility } from "hooks/useExecutivePartnershipsEligibility";
import { InteractionHeaderVariant } from "models/Interaction";
import { usePillStateConfig } from "components/SurveysPage/surveysColumns/surveyStatus/pillStateConfig";
import { RelevantCompanyLogo } from "components/RelevantCompanyLogo";

export const InteractionHeader = ({
  interaction,
  isProjectCidEnabled,
  pendingRequest = undefined,
  showCalendarView = false,
  userCidEnabled,
  researchRequest = undefined,
  showActionIcons = false,
  showNewlyAddedMark = false,
  onClick = undefined,
  isMobileLayout = false,
  locked,
  onRequest = undefined,
  currentView = undefined,
  statusComponent,
  setPlusXInteractionsElem = undefined,
  hideMoreInteractions = false,
  token,
  origin: originInput,
  workstreamId,
  pccOptOutWindow = 0,
  variant,
  ...props
}: {
  interaction: InteractionWithOtherCountsAndStates;
  isProjectCidEnabled: boolean;
  pendingRequest?: string;
  showCalendarView?: boolean;
  userCidEnabled: boolean;
  researchRequest?: any;
  showActionIcons?: boolean;
  showNewlyAddedMark?: boolean;
  onClick?: () => void;
  isMobileLayout?: boolean;
  locked?: boolean;
  onRequest?: (id: string, origin: string) => void;
  currentView?: string;
  statusComponent?: React.ReactNode;
  setPlusXInteractionsElem?: (ref: HTMLElement) => void;
  hideMoreInteractions?: boolean;
  token?: string;
  origin?: HitOrigin;
  workstreamId?: string;
  backgroundSyncRunning?: boolean;
  onCancelRequest?: (id: string, origin: HitOrigin) => void;
  onOpenComments?: (interaction: InteractionWithOtherCountsAndStates) => void;
  onRequestRescheduleInteraction?: (interactionId: string) => void;
  pccOptOutWindow?: number;
  variant?: InteractionHeaderVariant;
}) => {
  const {
    container,
    reEngageBannerStyles,
    firstLineStyles,
    actionsIconsStyles,
    labelStyles,
  } = useInteractionHeaderStyles({
    isMobile: isMobileLayout,
    variant,
  });

  const { backgroundSyncRunning, onCancelRequest, onOpenComments, onRequestRescheduleInteraction } = props;

  const origin = (originInput ?? currentView) as HitOrigin;

  const { setHideRef, onToggleHiddenWithMessage } = useHideExpertContext()!;
  const { onToggleStar } = useStarExpertContext()!;

  const hideContext = `${origin}flyout`;

  const { project } = useCurrentProjectContext();
  const selectedWorkstream = getWorkstream(project, workstreamId);
  const isSurveyWorkstream = selectedWorkstream?.workstreamType === "survey";
  const { isMobile } = useCheckScreen();

  const canRequestForSurvey =
    isSurveyWorkstream &&
    (interaction.state === "available" || interaction.state === "proposed") &&
    (interaction.surveyResponse?.state === "available" || isPotentialSurveyResponse(interaction));

  return (
    <x.div {...container} data-testid="interaction-header">
      <x.div {...firstLineStyles}>
        <AdvisorBadges
          interaction={interaction}
          isProjectCidEnabled={isProjectCidEnabled}
          userCidEnabled={userCidEnabled}
          locked={locked}
          variant={variant}
        />
        {showActionIcons && (
          <InteractionActionIcons
            backgroundSyncRunning={backgroundSyncRunning}
            isActiveProject={interaction.isActiveProject}
            pccOptOutWindow={pccOptOutWindow}
            interaction={interaction}
            showCommentsButton
            showMoreOptionsButton
            onClickComments={() => onOpenComments?.(interaction)}
            isExpertHidden={interaction.hidden}
            onClickStar={() => onToggleStar(interaction, origin)}
            isExpertStarred={interaction.starred}
            onCancelRequest={() => onCancelRequest?.(interaction.id, origin)}
            onRequestRescheduleInteraction={onRequestRescheduleInteraction}
            origin={origin}
            token={token}
            showLabelButton={currentView === "list-view"}
            {...actionsIconsStyles}
          />
        )}
      </x.div>
      <AdvisorPrimaryPosition
        interaction={interaction}
        pendingRequest={pendingRequest}
        showCalendarView={showCalendarView}
        onClick={onClick}
        showNewlyAddedMark={showNewlyAddedMark}
        statusComponent={statusComponent}
        setPlusXInteractionsElem={setPlusXInteractionsElem}
        hideMoreInteractions={hideMoreInteractions}
        variant={variant}
      />
      <AdvisorSecondaryPosition interaction={interaction} />
      {isMobile && <HasAvailabilityPill interaction={interaction} />}
      <InteractionLabel interaction={interaction} {...labelStyles} />
      {interaction.showRequestToReEngage && !canCreateResearchRequest(researchRequest) && (
        <InfoBanner {...reEngageBannerStyles}>
          Request to re-engage sent by {researchRequest.requester} on{" "}
          <FormattedDateTime date={researchRequest.createdAt} format="dd MMM yyyy" />
        </InfoBanner>
      )}
      <ProjectBadged badge={PORTAL_WORKSTREAM_BADGE}>
        <>
          {canRequestForSurvey && (
            <x.div flex row justifyContent={"space-between"} mt={"12px"} w={"100%"}>
              <CtaButton
                data-testid="survey-request"
                loading={interaction.runningAction === "followUp"}
                disabled={backgroundSyncRunning}
                mr={"4px"}
                flex={"1 1 0"}
                onClick={() => onRequest?.(interaction.id, `${currentView}-regular`)}
              >
                Request for Survey
              </CtaButton>
              <x.div ref={(ref) => setHideRef(interaction.id, ref, hideContext)} ml={"4px"} flex={"1 1 0"}>
                <CtaButton
                  data-testid="survey-hide"
                  variant={"outline"}
                  loading={interaction.runningAction === "followUp"}
                  disabled={backgroundSyncRunning}
                  w={"100%"}
                  onClick={() => onToggleHiddenWithMessage(interaction, origin, hideContext)}
                >
                  {interaction.hidden ? "Show" : "Hide"}
                </CtaButton>
              </x.div>
            </x.div>
          )}
        </>
      </ProjectBadged>
    </x.div>
  );
};

const AdvisorPrimaryPosition = ({
  interaction,
  pendingRequest,
  showCalendarView,
  onClick,
  showNewlyAddedMark,
  statusComponent,
  hideMoreInteractions,
  setPlusXInteractionsElem,
  variant = "default",
}: {
  interaction: InteractionWithOtherCountsAndStates;
  pendingRequest?: string;
  showCalendarView: boolean;
  onClick?: () => void;
  showNewlyAddedMark: boolean;
  statusComponent: React.ReactNode;
  hideMoreInteractions: boolean;
  setPlusXInteractionsElem?: (ref: HTMLElement) => void;
  variant?: InteractionHeaderVariant;
}) => {
  const {
    color: { text, background },
  } = useThemeTokens();
  const { isMobile } = useCheckScreen();
  const { primaryPositionDiv, secondLineDiv, textWrapper, spanTextStyles } = useInteractionHeaderStyles({
    isMobile,
    variant,
  });
  const isNewlyAdded = interaction.newlyAdded;

  const newlyAddedMark = (
    <div
      data-testid={`newly-added-mark-${interaction.id}`}
      style={{
        width: isNewlyAdded ? "8px" : 0,
        height: isNewlyAdded ? "8px" : 0,
        backgroundColor: background.selected.strong.default,
        borderRadius: "50%",
        alignSelf: "center",
        marginRight: isNewlyAdded ? "8px" : 0,
        visibility: isNewlyAdded ? "visible" : "hidden",
        opacity: isNewlyAdded ? "1" : "0",
        transition: "all 0.3s",
        display: "inline-block",
      }}
    />
  );

  return (
    <x.div onClick={onClick} cursor={onClick ? "pointer" : undefined} data-testid="advisor-card-highlight-group">
      <x.div {...primaryPositionDiv}>
        <x.div {...secondLineDiv}>
          {showNewlyAddedMark && newlyAddedMark}

          {statusComponent ? (
            statusComponent
          ) : (
            <AdvisorStatus pendingRequest={pendingRequest} showCalendarView={showCalendarView} {...interaction} />
          )}

          {!hideMoreInteractions && variant !== "card" && (
            <InteractionsCount interaction={interaction} setPlusXInteractionsElem={setPlusXInteractionsElem} />
          )}

          {variant !== "card" && (
            <Typography variant={isMobile ? "body-em" : "body-large-em"} component="span" color={text.strong._}>
              {" "}
              •{" "}
            </Typography>
          )}
          {variant === "flyout" && (
            <RelevantCompanyLogo projectToken={interaction.projectToken} interactionId={interaction.id} size="medium" />
          )}
          <x.div {...textWrapper}>
            <x.span {...spanTextStyles}>
              {interaction.companyName && interaction.role ? (
                <>
                  <HighlightTextTypography
                    highlights={interaction.doubleBlinded ? [] : interaction.highlights}
                    fieldNames={[
                      "advisorships.relevant_company_name",
                      "advisorships.relevant_company_name.concat",
                      "relevantPrimaryJob.companyName",
                      "relevantPrimaryJob.companyName.concat",
                    ]}
                    text={`${interaction.companyName}`}
                    variant={isMobile ? "body-em" : "body-large-em"}
                    component="span"
                    color={text.strong._}
                  />
                  <Typography variant={isMobile ? "body-em" : "body-large-em"} component="span" color={text.strong._}>
                    {" - "}
                  </Typography>
                  <HighlightTextTypography
                    highlights={interaction.doubleBlinded ? [] : interaction.highlights}
                    fieldNames={[
                      "advisorships.relevant_job_title",
                      "advisorships.relevant_job_title.concat",
                      "relevantPrimaryJob.jobTitle",
                      "relevantPrimaryJob.jobTitle.concat",
                    ]}
                    text={interaction.role}
                    variant={isMobile ? "body-em" : "body-large-em"}
                    component="span"
                    color={text.strong._}
                  />
                </>
              ) : (
                <Typography variant={isMobile ? "body-em" : "body-large-em"} component="span">
                  No Position Found
                </Typography>
              )}
              <ProjectBadged badge={PORTAL_WORKSTREAM_BADGE}>
                <>
                  {shouldShowTransId(interaction) && (
                    <Tooltip title={"Expert Identifier"}>
                      <Typography
                        variant={isMobile ? "body-em" : "body-large-em"}
                        data-testid="flyout-survey-transId"
                        component="span"
                        textTransform="none"
                        color={text.strong._}
                      >
                        {` • ${interaction.surveyResponse.transId}`}
                      </Typography>
                    </Tooltip>
                  )}
                </>
              </ProjectBadged>
            </x.span>
          </x.div>
        </x.div>

        {interaction.rolePeriod && interaction.rolePeriod.length > 0 && (
          <Typography variant={isMobile ? "body" : "body-large"} component="div" color="secondary">
            {interaction.rolePeriod}
          </Typography>
        )}
      </x.div>
    </x.div>
  );
};

export const AdvisorStatus = ({
  variant = "default",
  ...props
}: Interaction & { variant?: InteractionHeaderVariant }) => {
  const {
    hidden,
    state,
    followUpId,
    previousAdvisorshipId,
    showCalendarView = false,
    pendingRequest,
    surveyResponse,
    interactionType,
  } = props;

  const { isMobile } = useCheckScreen();
  const { color } = useThemeTokens();
  const { advisorStatus } = useInteractionHeaderStyles();
  const { hasProjectBadge } = useProjectBadgeContext();

  const hasPortalWorkstreams = hasProjectBadge(PORTAL_WORKSTREAM_BADGE);

  const isSurvey = interactionType === "Industry Survey";
  const isSurveyState = isSurvey && hasPortalWorkstreams && surveyResponse;
  const stateColor = isSurveyState ? getSurveyStateColorFlyout(surveyResponse.state) : getStateColorFlyout(state);
  const displayColor = hidden ? color.text.secondary : stateColor;
  const pillStateConfig = usePillStateConfig();
  const stateConfig = pillStateConfig[state];
  const pillVariant = stateConfig?.variant ?? "light";
  const pillIcon = stateConfig?.icon;

  const getSurveyStateName = (str: string) =>
    str.replace(/_([a-z])/g, (_, w) => ` ${w.toUpperCase()}`).replace(/^./, (s) => s.toUpperCase());

  const stateName = isSurveyState
    ? getSurveyStateName(surveyResponse.state)
    : getStateName({
        state,
        previousAdvisorshipId,
        isExpertHidden: hidden,
        followUpId,
        showCalendarView,
        pendingRequest: pendingRequest ? { type: pendingRequest } : undefined,
      });

  const capitalizedStateName = stateName.charAt(0).toUpperCase() + stateName.slice(1);

  const content = (
    <Typography
      variant={variant === "card" ? "body-small" : isMobile ? "body-em" : "body-large-em"}
      color={displayColor}
      component="span"
      {...(variant !== "card" ? advisorStatus : {})}
    >
      {capitalizedStateName}
    </Typography>
  );

  return variant === "card" ? (
    <Pill
      size="small"
      variant={pillVariant}
      isInteractive={false}
      leftAccessories={pillIcon}
      data-testid={`advisor-name-${state}`}
    >
      {content}
    </Pill>
  ) : (
    <x.div display="inline">
      <Typography variant={isMobile ? "h2" : "h3"} data-testid={`advisor-name-${state}`} component="span">
        <TooltipStateDetail interaction={props} pendingRequest={pendingRequest}>
          {content}
        </TooltipStateDetail>
      </Typography>
    </x.div>
  );
};

const InteractionsCount = ({
  interaction,
  setPlusXInteractionsElem,
  variant = "default",
}: {
  interaction: InteractionWithOtherCountsAndStates;
  setPlusXInteractionsElem?: (ref: HTMLElement) => void;
  variant?: "default" | "card";
}) => {
  const { isMobile } = useCheckScreen();
  const { color } = useThemeTokens();

  const otherInteractions = interaction.othersCounts
    ? Object.values(interaction.othersCounts).reduce((partialSum, a) => partialSum + a, 0)
    : 0;

  if (!otherInteractions) {
    return null;
  }

  const contentText = variant === "card" ? `+${otherInteractions} more` : `, +${otherInteractions} more`;

  const content = (
    <Typography
      ref={setPlusXInteractionsElem}
      variant={variant === "card" ? "body-small" : isMobile ? "body-em" : "body-large-em"}
      component="span"
      color={color.text.secondary}
    >
      {contentText}
    </Typography>
  );

  return variant === "card" ? (
    <Pill size="small" variant="outline" isInteractive={false}>
      {content}
    </Pill>
  ) : (
    content
  );
};

const AdvisorSecondaryPosition = ({ interaction }: { interaction: Interaction }) => {
  const { isMobile } = useCheckScreen();
  const { secondaryPositionDiv, secondaryPositionTitle } = useInteractionHeaderStyles();
  const displayTitle = interaction.doubleBlinded ? "Company and position withheld" : interaction.secondaryTitle;

  if (!interaction.secondaryTitle) {
    return null;
  }

  return (
    <x.div {...secondaryPositionDiv}>
      <Typography component="span" variant={isMobile ? "body-small" : "body"} {...secondaryPositionTitle}>
        {`Secondary: ${displayTitle}`}
        {interaction.secondaryRolePeriod && ` • ${interaction.secondaryRolePeriod}`}
      </Typography>
    </x.div>
  );
};

const AdvisorBadges = ({
  interaction,
  isProjectCidEnabled,
  userCidEnabled,
  locked,
  variant,
}: {
  interaction: Interaction;
  isProjectCidEnabled: boolean;
  userCidEnabled: boolean;
  locked?: boolean;
  variant?: InteractionHeaderVariant;
}) => {
  const { advisorBadges } = useInteractionHeaderStyles();
  const { project, isFeatureDisabled } = useCurrentProjectContext();
  const needsInterpreter = useMemo(() => getLanguageFromCode(interaction.needsInterpreter), [
    interaction.needsInterpreter,
  ]);
  const showAlphaCircleMultipler =
    !project?.showAdvisorRateAsCurrency &&
    interaction.isActiveProject &&
    (interaction.alphaCircleMultiplier ?? 1.0) > 1.0 &&
    !isFeatureDisabled(ProjectFeature.AlphaCircleMultiplier);

  const { isEligible: isEligibleForExecutivePartnership } = useExecutivePartnershipsEligibility({
    projectToken: project?.token,
    interactionId: interaction.id,
  });

  return (
    <x.div {...advisorBadges}>
      {locked && <LockedBadge />}
      {variant === "card" && (
        <>
          <AdvisorStatus {...interaction} variant="card" />
          <InteractionsCount interaction={interaction as InteractionWithOtherCountsAndStates} variant="card" />
        </>
      )}

      {interaction.group?.name && <AngleBadge value={interaction.group.name} />}
      {interaction.isActiveProject && isProjectCidEnabled && interaction.externalCidStatus && (
        <CidBadge userCidEnabled={userCidEnabled} externalCidStatus={interaction.externalCidStatus}></CidBadge>
      )}
      {interaction.highlyRecommended && <HighlyRecommendedBadge />}
      {showAlphaCircleMultipler && <AlphaCircleBadge value={interaction.alphaCircleMultiplier!} />}
      {isEligibleForExecutivePartnership && <ExecutivePartnershipBadge />}
      {interaction.geographies && interaction.geographies.length > 0 && (
        <GeographicKnowledgeBadge value={interaction.geographies} />
      )}
      {needsInterpreter && <NeedsInterpreterBadge value={needsInterpreter} />}

      {project?.showAdvisorRateAsCurrency && <PriceEstimationBadge interaction={interaction} />}
    </x.div>
  );
};

const { color: colorTokens } = tokens;
const getCidStatusColor = (status: CidStatus) => {
  switch (status) {
    case CidStatus.BLOCKED:
      return colorTokens.base.orange[100];
    case CidStatus.CLEARED:
      return colorTokens.background.success;
    case CidStatus.APPROVED:
      return colorTokens.background.processing.default;
    case CidStatus.PENDING:
      return colorTokens.background.neutral.default;
    default:
      return undefined;
  }
};

export const CidBadge = ({
  userCidEnabled,
  externalCidStatus,
  useFullWidth = false,
}: {
  userCidEnabled: boolean;
  externalCidStatus: CidStatus;
  useFullWidth?: boolean;
}) => (
  <>
    {userCidEnabled ? (
      <VisibleCidStatusBadge externalCidStatus={externalCidStatus} />
    ) : (
      <HiddenCidStatusBadge useFullWidth={useFullWidth} />
    )}
  </>
);

const VisibleCidStatusBadge = ({ externalCidStatus }: { externalCidStatus: CidStatus }) => {
  if (!externalCidStatus) return null;

  return (
    <Badge
      value={externalCidStatus.toLowerCase()}
      backgroundColor={getCidStatusColor(externalCidStatus)}
      textTransform="capitalize"
      data-testid="cid-status-badge"
    />
  );
};

const HiddenCidStatusBadge = ({ useFullWidth }: { useFullWidth: boolean }) => (
  <Badge useFullWidth={useFullWidth} tooltipText="You must be signed in to view the CID Status." />
);

const AngleBadge = ({ value }: { value: string }) => (
  <Badge value={value} icon={<Angle />} data-testid="angle-badge" characterLimit={26} />
);

const AlphaCircleBadge = ({ value }: { value: number }) => (
  <Badge
    tooltipText={`Available at a rate of ${value} credits per hour`}
    value={value.toString()}
    icon={<AlphaCircle />}
    data-testid="alphacircle-badge"
  />
);

const ExecutivePartnershipBadge = () => (
  <Badge
    tooltipText="Suitable for longer-term work via our Executive Partnerships team"
    icon={<SeniorExecutive />}
    data-testid="executive-partnership-badge"
  />
);

export const getGeographicKnowledgeText = (value: string[]) => {
  return value[0] + (value.length > 1 ? `, + ${value.length - 1} more` : "");
};

const GeographicKnowledgeBadge = ({ value }: { value: string[] }) => (
  <Badge
    tooltipText={
      <>
        Geographic knowledge:{" "}
        <Typography variant="body-small-em" component="span">
          {value.join(", ")}
        </Typography>
      </>
    }
    value={getGeographicKnowledgeText(value)}
    icon={<Globe />}
    data-testid="geographic-knowledge-badge"
  />
);

export const HighlyRecommendedBadge = () => (
  <Badge
    tooltipText="This expert is highly recommended."
    value="Highly Recommended"
    icon={<Recommended />}
    data-testid="highly-recommended-badge"
  />
);

const LockedBadge = () => <Badge icon={<Lock />} data-testid="locked-advisor-badge" />;

const NeedsInterpreterBadge = ({ value }: { value: string }) => (
  <Badge
    tooltipText={
      <>
        Needs interpreter:{" "}
        <Typography variant="body-small-em" component="span">
          {value}
        </Typography>
      </>
    }
    value={value}
    icon={<Interpreted />}
    data-testid="needs-interpreter-badge"
  />
);

const PriceEstimationBadge = ({ interaction }: { interaction: Interaction }) => {
  const [estimatedPrice, setEstimatedPrice] = useState<string>();

  useEffect(() => {
    InteractionsService.getEstimatedPrice(interaction.projectToken, interaction.id).then((response) => {
      setEstimatedPrice(response.estimatedPrice);
    });
  }, [interaction.id, interaction.projectToken]);

  if (!estimatedPrice) {
    return null;
  }

  return (
    <Badge
      tooltipText={<>Advisor available for {estimatedPrice} per hour.</>}
      value={`${estimatedPrice}/hr`}
      icon={<Credit />}
      data-testid="price-estimation-badge"
    />
  );
};

const HasAvailabilityPill = ({ interaction }: { interaction: Interaction }) => {
  const hasAvailability = (interaction?.advisorAvailability || []).length;
  const { spacing } = useThemeTokens();

  return hasAvailability && !interaction?.scheduled ? (
    <Pill
      isInteractive={false}
      size="small"
      style={{ height: spacing.inner.base06, marginTop: spacing.inner.base03 }}
      variant="green"
    >
      Expert has availability
    </Pill>
  ) : null;
};
