import { useRelevantCompanyLogo } from "app/services/useRelevantCompanyLogo";
import { useFlags } from "app/wrappers/FeatureFlagsWrapper";
import { useIsConsultingClientProject } from "app/services/useProject";
import { CompanyLogoComponent, CompanyLogoComponentProps } from "./CompanyLogoComponent";

export type RelevantCompanyLogoProps = {
  projectToken: string;
  interactionId: string;
  size?: CompanyLogoComponentProps["size"];
} & React.HTMLAttributes<HTMLDivElement>;

export const RelevantCompanyLogo = ({
  projectToken,
  interactionId,
  size = "large",
  ...props
}: RelevantCompanyLogoProps) => {
  const { enableCdsLogos } = useFlags();
  const isConsultingClient = useIsConsultingClientProject(projectToken);
  const logoQuery = useRelevantCompanyLogo(projectToken, interactionId, enableCdsLogos);

  if (!enableCdsLogos) {
    return null;
  }

  if (isConsultingClient) {
    return null;
  }

  const { data, isLoading } = logoQuery;

  return <CompanyLogoComponent logoUrl={data} loading={isLoading} size={size} {...props} />;
};
