import TextQuestionList from "./TextQuestionList";
import { CHART_TYPES, OneDimensionalTextResults, ZeroDimensionalTextResults } from "views/SurveysResultsView/api/types";

type TextQuestionProps = {
  chartType: typeof CHART_TYPES.ZERO_DIMENSIONAL_TEXT | typeof CHART_TYPES.ONE_DIMENSIONAL_TEXT;

  results: ZeroDimensionalTextResults | OneDimensionalTextResults;
};

const QuestionVisualisation = ({ chartType, results }: TextQuestionProps) => {
  switch (chartType) {
    case CHART_TYPES.ZERO_DIMENSIONAL_TEXT:
    case CHART_TYPES.ONE_DIMENSIONAL_TEXT:
      return <TextQuestionList chartType={chartType} results={results} />;
    default:
      return null;
  }
};

const TextQuestion = ({ chartType, results }: TextQuestionProps) => {
  return <QuestionVisualisation chartType={chartType} results={results} />;
};

export default TextQuestion;
