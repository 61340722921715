import { DialInNumber } from "@alphasights/portal-api-client";

export {};

// client-portal/backend/src/main/kotlin/com/alphasights/clientportal/web/rest/response/InteractionResponse.kt
declare global {
  interface SearchHighlight {
    fieldName: string;
    terms: string[];
  }

  interface InteractionEdge {
    cursor: string;
    highlights: SearchHighlight[];
    node: Interaction;
    score: number;
    updatedFields: string[];
  }

  interface Interaction {
    id: string;
    advisorId: string;
    previousAdvisorshipId?: string;
    followUpId?: string;
    group: Group;
    language?: string;
    state: string;
    workstreamId: string;
    surveyResponse: SurveyResponse;
    hidden: boolean;
    highlights?: SearchHighlight[];
    score: number;
    recordings: Recording[];
    projectToken: string; // added by reducer
    isActiveProject: boolean; // added by reducer
    customerKnowledges: CustomerKnowledge[];
    advisorCompany?: string;
    role?: string;
    advisorName: string;
    relevanceStatement?: string;
    proposedAt: string;
    newlyAdded: boolean;
    allowNonHourAutoBook: boolean;
    angles: Angle[];
    scheduledCallTime: string;
    dialInNumbers: DialInNumber[];
    newspeakProvider: string | null;
    clientAccessNumber: string | null;
    clientAccessCode: string | null;
    callMeUrl: string | null;
    needsInterpreter?: string;
    expectedDuration?: number;
    callQuality?: string;
    transcriptTargetLanguage?: string;
    doubleBlinded?: boolean;
    interactionType: string;
    alphaCircleMultiplier?: number;
    clientTimeslots?: {
      startsAt: string;
    }[];

    [key: string]: any;
  }

  interface Group {
    id: string;
    name: string;
    parent: Group;
    angleTypeName: string;
  }

  interface SurveyResponse {
    clientSurveyId: string;
    transId?: string;
    state: "available" | "requested" | "completed" | "partially_completed" | "sent" | "answered" | string;
  }

  interface AngleType {
    id: Number;
    name: string;
  }

  interface Angle {
    id: string;
    title: string;
    type?: AngleType;
    parent?: Angle;
  }

  interface Recording {
    id: string;
    visibleToClient: boolean;
    expiresAt?: string;
    purgedAt?: string;
    upgradableUntil?: string;
    upgradable: boolean;
    transcriptRequests: TranscriptRequest[];
    url?: string;
    obfuscated: boolean;
    createdAt?: string;
    length?: number;
  }

  type TranscriptType = "ai" | "regular";

  interface TranscriptRequest {
    id: string;
    token: string;
    completed: boolean;
    transcriptType: TranscriptType;
    purgedAt?: string;
    visibleToClientUpdatedAt?: string;
  }

  interface TranscriptStatus {
    value: string;
    timestamp: string;
  }

  interface TranscriptStatusList {
    transcriptId: string;
    status: TranscriptStatus[];
  }

  interface InteractionUpgrade {
    value: string;
    actionerType?: string;
  }

  interface Vendor {
    id: string;
    name: string;
  }

  interface CustomerKnowledge {
    id: number;
    vendor: Vendor;
    experience: Experience;
    role?: Role;
    lastEvaluatedAt?: string;
    rank: number;
    budget?: number;
    solutions: string[];
  }

  interface EstimatedPrice {
    estimatedPrice: string;
  }

  interface AccessPlan {
    hostedCallMultiplier: number;
    interpretedCallMultiplier: number;
    transcriptRate: number;
    transcriptChargeUnit?: ChargeUnit;
    hostedCallChargeUnit?: ChargeUnit;
    interpretedCallChargeUnit?: ChargeUnit;
  }

  interface Terms {
    acceptedAt?: string;
    transcriptConsentAccepted: boolean;
  }

  interface Expert {
    index: number;
    advisorId: string;
    angleId?: string;
    workstreamId: string;
    angleTypeName?: string;
    interactions: Interaction[];
  }
}

export enum Experience {
  Uses = "uses",
  Churned = "churned",
  Evaluated = "evaluated",
  Aware = "aware",
  Unaware = "unaware",
}

export enum Role {
  Kdm = "kdm",
  EvaluationTeam = "evaluation_team",
  User = "user",
}

export enum ChargeUnit {
  hour = "per_hour",
  interaction = "per_interaction",
}

export type InteractionHeaderVariant = "default" | "flyout" | "card";
