import { x } from "@xstyled/styled-components";
import Chart from "react-apexcharts";
import YAxis from "./YAxis";
import { useChartConfig } from "./useChartConfig";
import { OneDimensionalBarChartResults } from "views/SurveysResultsView/api/types";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { useSurveysResultsStore } from "views/SurveysResultsView/state";
import { useSurveyInsights } from "views/SurveysResultsView/api/useSurveysAPI";

type OneDimensionalBarChartProps = {
  results: OneDimensionalBarChartResults;
};

const OneDimensionalBarChart = ({ results }: OneDimensionalBarChartProps) => {
  const { spacing, color } = useThemeTokens();
  const surveyId = useSurveysResultsStore(({ surveyId }) => surveyId);
  const { data: surveyInsights } = useSurveyInsights(surveyId);
  const { chartHeight, config, values, labels } = useChartConfig({
    expertsCount: surveyInsights?.pages[0].total || 1,
    results,
  });

  const openEndedResponses = results.aggregated
    .flatMap((entry) => entry.openEndedResponses || [])
    .map((response) => response.text);

  return (
    <x.div display="flex" w="100%" data-testid="one-dimensional-bar-chart-container" flexDirection="column">
      <x.div display="flex">
        <YAxis labels={labels} />
        <x.div display="flex" flexDirection="column" flex="1 1 auto" data-testid="one-dimensional-bar-chart">
          <Chart options={config} series={values} type="bar" height={chartHeight} />
          <x.div display="flex" alignItems="center" justifyContent="center" padding={spacing.inner.base02}>
            <Typography variant="body-small" color={color.text.strong._}>
              % Customers
            </Typography>
          </x.div>
        </x.div>
      </x.div>
      {openEndedResponses.length > 0 && (
        <Typography variant="body-small" color={color.text.secondary} mt={spacing.inner.base02}>
          Other responses: {openEndedResponses.join(", ")}
        </Typography>
      )}
    </x.div>
  );
};

export default OneDimensionalBarChart;
