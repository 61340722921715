import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { Button, ButtonProps, useThemeTokens } from "@alphasights/alphadesign-components";
import styled, { CSSProperties } from "@xstyled/styled-components";

import { FilterViewWrapper } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/AlphaNowFilters.styled";
import { LOADING_BUTTON_WIDTH } from "pages/AlphaNowPage/components/AlphaNowSearch/AlphaNowFilters/FilterForm/constants";

const FilterFormWrapper = styled.div(() => {
  return {
    height: "-webkit-fill-available",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "flex-end",
  };
});

const FilterFormContainer = styled.div(({ maxHeight }: { maxHeight?: CSSProperties["maxHeight"] }) => {
  const { spacing } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  if (isMobile) {
    return {
      paddingInline: spacing.inner.base06,
      overflowY: "scroll" as "scroll",
      height: "-webkit-fill-available",
    };
  }

  return {
    width: "284px",
    paddingLeft: `${spacing.inner.base03} !important`,
    paddingRight: `${spacing.inner.base02} !important`,
    overflowY: "scroll" as "scroll",
    ...(maxHeight && { maxHeight }),
  };
});

const TitleWrapper = styled.div(() => {
  const { spacing } = useThemeTokens();
  return {
    marginBlock: spacing.inner.base06,
  };
});

const FilterFormButtonsWrapper = styled.div(() => {
  const { color, spacing, shape } = useThemeTokens();
  const { isMobile } = useCheckScreen();

  if (isMobile) {
    return {
      display: "flex",
      flexDirection: isMobile ? ("column-reverse" as "column-reverse") : ("column" as "column"),
      gap: spacing.inner.base05,
      backgroundColor: color.background.surface.page.default,
      bottom: 0,
      left: 0,
      right: 0,
      paddingInline: spacing.inner.base04,
      paddingTop: spacing.inner.base08,
      paddingBottom: spacing.inner.base08,
      borderTop: `${shape.border.width.small} solid ${color.border.divider}`,
      height: "fit-content",
    };
  }
  return {
    display: "flex",
    justifyContent: "space-between",
    paddingInline: spacing.inner.base04,
    paddingTop: spacing.inner.base03,
    paddingBottom: spacing.inner.base02,
    borderTop: `${shape.border.width.small} solid ${color.border.divider}`,
  };
});

const StyledConfirmButton = styled(Button)(({ loading }: ButtonProps) => {
  const { spacing } = useThemeTokens();
  const { isMobile } = useCheckScreen();
  return {
    padding: isMobile
      ? `${spacing.inner.base03} ${spacing.inner.base02}`
      : loading
      ? `${spacing.inner.base03} 0`
      : spacing.inner.base03,
    minWidth: isMobile ? "-webkit-fill-available" : LOADING_BUTTON_WIDTH,
  };
});

export {
  FilterFormWrapper,
  FilterFormContainer,
  FilterFormButtonsWrapper,
  TitleWrapper,
  FilterViewWrapper,
  StyledConfirmButton,
};
