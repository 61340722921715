import { useThemeTokens } from "@alphasights/alphadesign-components";
import { ApexOptions } from "apexcharts";
import { YAxisValueType } from "./YAxis";
import { OneDimensionalBarChartResults } from "views/SurveysResultsView/api/types";

type Props = {
  expertsCount?: number;
  results: OneDimensionalBarChartResults;
};

type Data = {
  logos?: { details: { value: string; isVendor: boolean }[]; valueType: YAxisValueType; align: string };
  vendors: { details: { value: string; isVendor: boolean }[]; valueType: YAxisValueType; align: string };
  quantities: string[];
};

const transformToArrays = (data: Data) => {
  const { logos, vendors } = data;

  if (logos) {
    return [
      { details: logos.details, valueType: logos.valueType, align: logos.align },
      { details: vendors.details, valueType: vendors.valueType, align: vendors.align },
    ];
  }
  return [{ details: vendors.details, valueType: vendors.valueType, align: vendors.align }];
};

const getValuesAndLabels = ({ expertsCount, results }: Props) => {
  const shouldIncludeLogos = results.aggregated.some((result) => result.isVendor);

  const { answerText, quantity } = results.aggregated.reduce(
    (acc, result) => {
      acc.answerText.push({ value: result.answerText, isVendor: result.isVendor });
      acc.quantity.push(result.quantity.toString());
      return acc;
    },
    { answerText: [], quantity: [] } as { answerText: { value: string; isVendor: boolean }[]; quantity: string[] }
  );

  return {
    ...(shouldIncludeLogos && {
      logos: {
        details: answerText,
        valueType: YAxisValueType.Logo,
        align: "left",
      },
    }),
    vendors: { details: answerText, valueType: YAxisValueType.Text, align: shouldIncludeLogos ? "left" : "right" },
    quantities: quantity.map((value) => Math.round((Number(value) * 100) / (expertsCount ?? 1)).toString()),
  };
};

export const useChartConfig = ({ expertsCount, results }: Props) => {
  const { color, font, typography } = useThemeTokens();
  const values = getValuesAndLabels({ expertsCount, results });

  const quantities = values.quantities;

  const labelsArray = transformToArrays(values);

  return {
    labels: labelsArray,
    values: [{ data: [...quantities.map((value) => Number(value))] }],
    chartHeight: quantities.length * 40 + 30,
    config: {
      chart: {
        animations: { enabled: false },
        fontFamily: font.family.text.regular,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        parentHeightOffset: 0,
        offsetX: 0,
        offsetY: 0,
      },
      tooltip: {
        enabled: false,
      },
      colors: [color.chart.sequential.base03],
      grid: {
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
        padding: { left: 0, right: 0, top: -30, bottom: -15 },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "28px",
        },
      },
      dataLabels: {
        enabled: false,
      },
      /**
       * X Axis options for the chart
       * https://apexcharts.com/docs/options/xaxis/
       */
      xaxis: {
        title: {
          txt: "Customers",
          style: {
            fontWeight: typography.body.small.fontWeight,
            fontSize: typography.body.small.fontSize,
            color: color.text.strong._,
          },
          offsetY: 15,
        },
        tickAmount: Math.ceil(Math.max(...quantities.map(Number)) / 5), // Ensure ticks are 5 units apart
        labels: {
          formatter: (value: any) => (Math.floor(value / 5) * 5).toString(), // Format labels to be multiples of 5
        },
        tooltip: {
          enabled: false,
        },
        axisBorder: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      fill: {
        opacity: 1,
      },
      title: {
        text: undefined,
      },
      legend: {
        show: false,
      },
      // this avoids the color to be darker when clicking on an entry
      states: {
        active: {
          filter: {
            type: "none",
          },
        },
      },
    } as ApexOptions,
  };
};
