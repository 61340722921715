import { List, ListOption, Popover } from "@alphasights/alphadesign-components";
import { MoreVert } from "@alphasights/alphadesign-icons";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { x } from "@xstyled/styled-components";
import { CidCompaniesModal } from "components/CidCompaniesModal";
import { projectSettingsTabs } from "components/ProjectSettingsModal";
import { useUserCidEnabled } from "hooks/useUserCidEnabled";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import { useProjectMoreOptionsContext } from "providers/ProjectMoreOptionsProvider";
import { useRef, useState } from "react";
import * as S from "./ProjectMoreOptions.styled";
import { sortBy } from "lodash";
import { ProjectFeature } from "@alphasights/portal-api-client";

export const ProjectMoreOptions = () => {
  const userCidEnabled = useUserCidEnabled();
  const { project, isFeatureDisabled } = useCurrentProjectContext();
  const currentUser = useCurrentUser();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [showingCidModal, setShowingCidModal] = useState(false);
  const {
    onOpenDownloads,
    onOpenCostOverview,
    onOpenProjectSettings,
    currentView,
    showCidOnSurveyView,
    performSearchSync,
  } = useProjectMoreOptionsContext();

  const uploadCidAvailabilityByView = new Map([
    ["table-view", true],
    ["customer-view", true],
    ["list-view", true],
    ["comparison-view", true],
    ["survey-view", showCidOnSurveyView],
  ]);

  const onUploadCid =
    project?.active && userCidEnabled && project.cidEnabled && uploadCidAvailabilityByView.get(currentView)
      ? () => setShowingCidModal(true)
      : undefined;

  const isPCCEnabledClient = project?.peerContentContributor;
  const isPTLEnabledClient = project?.privateTranscriptContributor;
  const settingsTabs = projectSettingsTabs({
    isPCCEnabledClient,
    isPTLEnabledClient,
    isPeerContentContributor: undefined,
    isPrivateTranscriptContributor: undefined,
  });

  const showExportLinks =
    project?.exportFileAccess === "ALL" || (project?.exportFileAccess === "INTERNAL" && currentUser?.internalUser);

  const showSettingsOption = currentUser && settingsTabs.length && !isFeatureDisabled(ProjectFeature.Settings);

  const closeAfter = (action?: Function) =>
    action &&
    ((args?: any) => {
      action(args);
      setOpen(false);
    });

  const options = {
    projectSection: sortBy(
      [
        {
          label: "Settings",
          testId: "item-project-settings",
          onClick: showSettingsOption ? closeAfter(onOpenProjectSettings) : undefined,
          order: 2,
        },
        {
          label: "Usage",
          testId: "item-view-usage",
          onClick: isFeatureDisabled(ProjectFeature.Usage) ? undefined : closeAfter(onOpenCostOverview),
          order: 3,
        },
        {
          label: "Download Excel",
          testId: "item-download-excel",
          onClick:
            isFeatureDisabled(ProjectFeature.Download) || !showExportLinks ? undefined : closeAfter(onOpenDownloads),
          order: 4,
        },
        {
          label: "Upload Bain CID",
          testId: "upload-cid",
          onClick: closeAfter(onUploadCid),
          order: 1,
        },
      ].filter((o) => !!o.onClick),
      (item) => item.order
    ),
  };

  if (options.projectSection.length === 0) {
    return null;
  }

  return (
    <>
      <S.MoreOptionsButton
        variant="ghost"
        color="inverse"
        ref={anchorRef}
        onClick={() => setOpen(!open)}
        isSelectable
        isSelected={open}
        testId="more-options-button"
      >
        <MoreVert />
      </S.MoreOptionsButton>
      {open && (
        <Popover
          open
          anchorEl={anchorRef?.current ?? undefined}
          onClose={() => setOpen(false)}
          zIndex={29}
          placement="bottom-end"
          closeOnClickOutside={true}
        >
          <Options options={options.projectSection} />
        </Popover>
      )}
      {project && (
        <CidCompaniesModal
          projectToken={project.token}
          isModalOpen={showingCidModal}
          onCidCompaniesModalUpload={performSearchSync ?? (() => {})}
          onClose={() => setShowingCidModal(false)}
        />
      )}
    </>
  );
};

type OptionsProps = {
  options: { label: string; testId: string; onClick?: () => void }[];
};

const Options = ({ options }: OptionsProps) => {
  return (
    <List data-testid="more-options-content">
      <ListOption type="title" label="Options" />
      {options.map(({ label, testId, onClick }) => (
        <x.div key={label} onClick={onClick}>
          <ListOption data-testid={testId} key={label} label={label} type="text" />
        </x.div>
      ))}
    </List>
  );
};
