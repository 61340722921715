import { memo } from "react";
import { CHART_TYPES, Results } from "views/SurveysResultsView/api/types";
import { x } from "@xstyled/styled-components";
import {
  OneDimensionalBarChart,
  OneDimensionalFloatingBarChart,
  TextQuestion,
  TwoDimensionalStackedBarChart,
  TextQuestionTabs,
} from "./VisualisationTypes";
import { useSurveysResultsStore } from "views/SurveysResultsView/state";
import { useSurveyResults } from "views/SurveysResultsView/api";
import { Skeleton } from "@alphasights/alphadesign-components";
import ThreeDimensionalKpcContainer from "./VisualisationTypes/ThreeDimensionalKpcChart/ThreeDimensionalKpcContainer";
import ExpandCollapseContainer from "./ExpandCollapseContainer";

const SurveyResultsVisualisation = memo(({ result }: { result: Results }) => {
  const { chartType, results } = result;

  switch (chartType) {
    case CHART_TYPES.ONE_DIMENSIONAL_BAR_CHART:
    case CHART_TYPES.ONE_DIMENSIONAL_BAR_CHART_CATEGORICAL:
      return <OneDimensionalBarChart results={results} />;
    case CHART_TYPES.ONE_DIMENSIONAL_FLOATING_BAR_CHART_DIVERGING:
    case CHART_TYPES.ONE_DIMENSIONAL_FLOATING_BAR_CHART_SEQUENTIAL:
      return <OneDimensionalFloatingBarChart results={results} chartType={chartType} />;
    case CHART_TYPES.ONE_DIMENSIONAL_STACKED_BAR_CHART_LIKERT:
    case CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART:
    case CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_DIVERGING:
    case CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_LIKERT:
    case CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_NET_PROMOTER_SCORE:
    case CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_SEQUENTIAL:
      return <TwoDimensionalStackedBarChart chartType={chartType} results={results} />;
    case CHART_TYPES.ZERO_DIMENSIONAL_TEXT:
    case CHART_TYPES.ONE_DIMENSIONAL_TEXT:
      return <TextQuestion chartType={chartType} results={results} />;
    case CHART_TYPES.TWO_DIMENSIONAL_TEXT_NPS:
      return <TextQuestionTabs chartType={chartType} results={results} />;
    case CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_DIVERGENT:
    case CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_SEQUENTIAL:
    case CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_CATEGORICAL:
    case CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_LIKERT:
    case CHART_TYPES.THREE_DIMENSIONAL_SCATTER_CHART_KPC:
      return <ThreeDimensionalKpcContainer results={results} chartType={chartType} />;
    case CHART_TYPES.ONE_DIMENSIONAL_TEXT_GRID:
      return <>not implemented yet</>;
    default:
      return null;
  }
});

const VisualisationTypeFactory = memo(({ questionId }: { questionId: string }) => {
  const surveyId = useSurveysResultsStore(({ surveyId }) => surveyId);
  const { data: result, isLoading } = useSurveyResults(surveyId, [questionId]);

  if (isLoading) {
    return <Skeleton count={7} />;
  }

  if (!result?.items.length) {
    return null;
  }

  return (
    <x.div data-testid="visualisation-type-factory">
      <ExpandCollapseContainer>
        <SurveyResultsVisualisation result={result?.items[0]} />
      </ExpandCollapseContainer>
    </x.div>
  );
});

export default VisualisationTypeFactory;
