import { x } from "@xstyled/styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { PrimerCompanyLogo } from "pages/AlphaNowPage/primers/components";
import ConditionalTooltip, { getTruncatedTextAndTooltip } from "components/ConditionalTooltip";
import { useSurveysResultsStore } from "views/SurveysResultsView/state";
import { useSurveyMetadata } from "views/SurveysResultsView/api";
import { CompanyDetails, TransformedSurveyMetadata } from "views/SurveysResultsView/api/types";

export enum YAxisValueType {
  Text,
  Logo,
}
type YAxisProps = {
  labels: {
    details: {
      value: string;
      isVendor: boolean;
    }[];
    valueType: YAxisValueType;
    align: string;
  }[];
};

type YAxisValueProps = {
  value: string;
  valueType: YAxisValueType;
  companyDetails: CompanyDetails[];
  isVendor: boolean;
};

const YAxisValue = ({ value, valueType, companyDetails, isVendor }: YAxisValueProps) => {
  const { icons, color } = useThemeTokens();

  if (valueType === YAxisValueType.Logo) {
    if (!isVendor) {
      return null;
    }

    const vendor = companyDetails.find((company) => company.name === value);
    return <PrimerCompanyLogo size={icons.size.lg} src={vendor?.logoUrl} />;
  }

  // We decided not to use the ADS EllipsisText component here because it has some
  // issues with performance when used with ApexCharts.
  const [truncatedValue, tooltip] = getTruncatedTextAndTooltip(value, 15);

  return (
    <ConditionalTooltip tooltip={(tooltip as string) ?? ""}>
      <Typography variant="body-small" color={color.text.strong._}>
        {(truncatedValue as string) ?? ""}
      </Typography>
    </ConditionalTooltip>
  );
};

const YAxis = ({ labels }: YAxisProps) => {
  const { spacing } = useThemeTokens();

  const surveyId = useSurveysResultsStore(({ surveyId }) => surveyId);
  const { data: surveyMetadata } = useSurveyMetadata(surveyId);
  const { companyDetails } = surveyMetadata as TransformedSurveyMetadata;

  return (
    <x.div display="flex" gap={spacing.layout.base02}>
      {labels.map(({ details, valueType, align }, index) => (
        <x.div key={index} flex="0 0 auto" display="flex" flexDirection="column" maxWidth="128px">
          {details.map(({ value, isVendor }, index) => (
            <x.div
              key={index}
              h="40px"
              w="100%"
              userSelect="none"
              alignItems="center"
              display="flex"
              justifyContent={align}
              columnGap={spacing.inner.base02}
            >
              <YAxisValue value={value} valueType={valueType} companyDetails={companyDetails} isVendor={isVendor} />
            </x.div>
          ))}
        </x.div>
      ))}
    </x.div>
  );
};

export default YAxis;
