import { useMemo, useState } from "react";
import { x } from "@xstyled/styled-components";
import { EllipsisText, TabItem, Tabs, useThemeTokens } from "@alphasights/alphadesign-components";
import {
  CHART_TYPES,
  TransformedSurveyMetadata,
  TwoDimensionalStackedBarChartNPSResults,
  ZeroDimensionalTextResults,
} from "views/SurveysResultsView/api/types";
import TextQuestionList from "./TextQuestionList";
import { useSurveysResultsStore } from "views/SurveysResultsView/state";
import { useSurveyMetadata } from "views/SurveysResultsView/api";
import { PrimerCompanyLogo } from "pages/AlphaNowPage/primers/components";

type TextQuestionTabsProps = {
  chartType: typeof CHART_TYPES.TWO_DIMENSIONAL_TEXT_NPS;
  results: TwoDimensionalStackedBarChartNPSResults;
};

const TextQuestionTabs = ({ chartType, results }: TextQuestionTabsProps) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { color, spacing } = useThemeTokens();
  const surveyId = useSurveysResultsStore(({ surveyId }) => surveyId);
  const { data: surveyMetadata } = useSurveyMetadata(surveyId);
  const { companyDetails } = surveyMetadata as TransformedSurveyMetadata;

  const answersAsZeroDimensionalTextResults: ZeroDimensionalTextResults[] = useMemo(
    () =>
      results.aggregated.map(({ x: row, yElements }) => {
        return {
          aggregated: yElements,
          average: null,
        };
      }),
    [results]
  );

  return (
    <>
      <Tabs
        colorVariant="primary"
        index={selectedTab}
        onChange={(index) => {
          if (index === undefined) return;

          setSelectedTab(index as number);
        }}
      >
        {results.aggregated.map(({ x: row, yElements }) => {
          // if the tab has no responses, don't show it
          if (yElements.length === 0 || yElements.every((element) => element.openEndedResponses.length === 0))
            return null;

          const { answerText, isVendor } = row;

          const vendor = companyDetails.find((company) => company.name === row.answerText);

          return (
            <TabItem
              key={answerText}
              label={
                <x.div display="flex" gap="2" alignItems="start">
                  {isVendor && <PrimerCompanyLogo size={spacing.inner.base05} src={vendor?.logoUrl} />}
                  <EllipsisText
                    typographyProps={{
                      variant: "body-small",
                      component: "p",
                      color: color.text.strong._,
                    }}
                  >
                    {answerText}
                  </EllipsisText>
                </x.div>
              }
            />
          );
        })}
      </Tabs>

      <x.div display="flex" gap={spacing.inner.base05} flexDirection="column" marginTop={spacing.inner.base04}>
        <TextQuestionList chartType={chartType} results={answersAsZeroDimensionalTextResults[selectedTab]} />
      </x.div>
    </>
  );
};

export default TextQuestionTabs;
