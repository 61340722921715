import { useCheckScreen } from "@alphasights/ads-community-hooks";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { FlyoutConfiguration } from "./enums";

export const useAdvisorFlyOutStyles = ({
  flyoutConfiguration = FlyoutConfiguration.Standard,
  listOpened = false,
  showMobileView = false,
} = {}) => {
  const {
    color: { border },
    spacing: { layout },
    shape,
  } = useThemeTokens();

  const header = {
    display: "flex",
    pt: showMobileView ? 0 : layout.base03,
    pb: showMobileView ? 0 : layout.base02,
    pl: showMobileView ? 0 : layout.base03,
    pr: showMobileView ? 0 : layout.base04,
    background: "white",
    alignItems: "flex-start",
    borderBottomWidth: "1px",
    borderStyle: "solid",
    borderColor: border.neutral.default,
    position: "absolute",
    w: "100%",
    boxShadow: listOpened ? shape.shadow.secondary : undefined,
    ...(showMobileView && {
      position: "fixed",
      top: 0,
    }),
  };

  const container = {
    display: "flex",
    flexDirection: "column",
    w: "100%",
    h: "100%",
    alignItems: "stretch",
    position: "relative",
    borderLeft: `${shape.border.width.sm} solid ${border.divider}`,
  };

  const body = {
    display: "flex",
    background: "white",
    flexGrow: "1",
    minHeight: 0,
    mt: showMobileView ? "65px" : "93px",
    position: "relative",
  };

  const standardContent = {
    overflow: "auto",
    p: showMobileView ? layout.base02 : layout.base03,
    flexGrow: flyoutConfiguration === FlyoutConfiguration.Standard ? 1 : undefined,
    display: showMobileView && flyoutConfiguration === FlyoutConfiguration.Extended ? "none" : undefined,
  };

  const extendedContent = {
    overflow: !showMobileView ? "auto" : undefined,
    py: showMobileView ? 0 : layout.base04,
    px: showMobileView ? 0 : flyoutConfiguration === FlyoutConfiguration.Extended ? layout.base04 : 0,
  };
  const extendedContentMobile = {
    position: "absolute",
    top: 0,
    zIndex: 10,
    background: "white",
    right: 0,
    left: 0,
    bottom: 0,
  };

  const fullScreenContent = {
    flex: 1,
  };

  const sidebar = {
    minHeight: 0,
  };

  return {
    header,
    container,
    body,
    standardContent,
    extendedContent,
    extendedContentMobile,
    fullScreenContent,
    sidebar,
  };
};

export const useTopbarFlyoutStyles = ({ flyoutConfiguration }) => {
  const {
    color: { text },
    spacing: { inner },
  } = useThemeTokens();

  const { isMobile } = useCheckScreen();

  const containerStyles = {
    display: "flex",
    gap: inner.base04,
  };

  const headerStyles = {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    gap: inner.base02,
    minWidth: "0",
  };

  const advisorInfoStyles = {
    display: "flex",
    alignItems: "center",
    gap: inner.base04,
  };

  const advisorNameStyles = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: text.strong._,
  };

  const advisorInteractionsStyles = {
    display: "flex",
    alignItems: "center",
    gap: inner.base02,
    minHeight: inner.base06,
    flexWrap: "wrap",
    ml: inner.base06,
    pl: isMobile ? inner.base04 : inner.base03,
  };

  const callToActionsStyles = {
    display: "flex",
    alignItems: "center",
    gap: inner.base,
  };

  const interactionListStyles = {
    pt: inner.base02,
  };

  const maximumChargeStyles = {
    minH: inner.base06,
    pl: inner.base03,
    ml: inner.base06,
    display: "flex",
    alignItems: "center",
  };

  return {
    containerStyles,
    headerStyles,
    advisorInfoStyles,
    advisorNameStyles,
    advisorInteractionsStyles,
    callToActionsStyles,
    interactionListStyles,
    maximumChargeStyles,
  };
};

export const useInteractionActionIconstyles = () => {
  const { isMobile } = useCheckScreen();
  const {
    spacing: { inner },
  } = useThemeTokens();

  const iconsActionsStyles = {
    display: "flex",
    gap: inner.base04,
    alignItems: "center",
  };

  const iconButtonStyles = {
    h: isMobile ? inner.base06 : inner.base05,
    w: isMobile ? inner.base06 : inner.base05,
    fontSize: isMobile ? inner.base06 : inner.base05,
  };

  return {
    iconsActionsStyles,
    iconButtonStyles,
  };
};

export const useInteractionDetailsStyles = () => {
  const {
    spacing: { inner },
  } = useThemeTokens();

  const interactionScheduledTimeStyles = {
    display: "flex",
    alignItems: "flex-start",
    gap: inner.base,
  };

  const interactionScheduledTimeTextStyles = {
    display: "flex",
    flexDirection: "column",
    paddingTop: "2.5px",
  };

  const containerStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const titleStyles = {
    display: "flex",
    gap: inner.base02,
    alignItems: "center",
  };

  return {
    interactionScheduledTimeStyles,
    interactionScheduledTimeTextStyles,
    containerStyles,
    titleStyles,
  };
};
