import { useMemo } from "react";
import { ApexOptions } from "apexcharts";
import { useThemeTokens } from "@alphasights/alphadesign-components";
import { CHART_TYPES, OneDimensionalFloatingBarChartResults } from "views/SurveysResultsView/api/types";
import { labelToMultiline } from "pages/AlphaNowPage/primers/utils/charts";
import { getIntervalledColors } from "views/SurveysResultsView/components/DataVisualization/utils/utils";

type Props = {
  results: OneDimensionalFloatingBarChartResults;
  chartType:
    | CHART_TYPES.ONE_DIMENSIONAL_FLOATING_BAR_CHART_DIVERGING
    | CHART_TYPES.ONE_DIMENSIONAL_FLOATING_BAR_CHART_SEQUENTIAL;
};

const getValues = (results: OneDimensionalFloatingBarChartResults) => {
  return {
    quantities: [
      {
        data: results.aggregated.map(({ answerText, start, end }) => {
          return { x: answerText, y: [start, end] };
        }),
      },
    ],
  };
};

export const useChartConfig = ({ results, chartType }: Props) => {
  const { font, typography } = useThemeTokens();
  const { quantities } = getValues(results);
  const divergingColors = [
    "#DC2929", // Red
    "#D65454",
    "#D27171",
    "#CE8D8D",
    "#CAAAAA",
    "#C6C6C6", // Gray (middle)
    "#A3BBB3",
    "#80AFA0",
    "#5EA38D",
    "#3B987B",
    "#08875F", // Green
  ];

  const sequentialColors = [
    "#8ABBFB", // Light Blue
    "#77ADF9",
    "#67A2F9",
    "#5695F7",
    "#4388F6",
    "#397CEA",
    "#3571D9",
    "#3065C5",
    "#2B59B1",
    "#264D9E",
    "#22428C",
    "#1D3679",
    "#1B326E",
    "#192D63",
    "#162858",
    "#14244D",
    "#101E40",
    "#0E1935", // Dark Blue
  ];

  const colors = {
    [CHART_TYPES.ONE_DIMENSIONAL_FLOATING_BAR_CHART_DIVERGING]: divergingColors,
    [CHART_TYPES.ONE_DIMENSIONAL_FLOATING_BAR_CHART_SEQUENTIAL]: getIntervalledColors(
      sequentialColors,
      results.aggregated.length
    ),
  }[chartType];

  // this is to get multilines x-axis labels
  const getXAxisLabels = useMemo(
    () => () => results.aggregated.map(({ answerText }, index) => labelToMultiline(answerText, 15)),
    [results]
  );

  return {
    colors,
    values: quantities,
    chartHeight: 500,
    config: {
      chart: {
        animations: { enabled: false },
        fontFamily: font.family.text.regular,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        parentHeightOffset: 0,
      },
      tooltip: {
        enabled: false,
      },
      colors: [
        function ({ dataPointIndex }: { dataPointIndex: number }) {
          // this is the only way we can get multiple colors in a rangeBar chart
          return colors[dataPointIndex];
        },
      ],
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        padding: { left: 30, right: 0, top: 0, bottom: 0 },
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        axisTicks: { show: false },
        categories: getXAxisLabels(),
      },
      yaxis: {
        tickAmount: 10,
        decimalsInFloat: 0,
      },
      annotations: {
        yaxis: [
          {
            y: 50,
            strokeDashArray: 10,
            borderColor: "#000",
            label: {
              borderColor: "transparent",
              style: {
                color: "#000",
                fontFamily: font.family.text.regular,
                fontSize: typography.body.small.fontSize,
                fontWeight: typography.body.small.fontWeight,
              },
              text: "Median",
              textAnchor: "start",
              position: "left",
            },
          },
        ],
      },
      // this avoids the color to be darker when clicking on an entry
      states: {
        active: {
          filter: {
            type: "none",
          },
        },
      },
    } as ApexOptions,
  };
};
