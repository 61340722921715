import { x } from "@xstyled/styled-components";
import { Typography, useThemeTokens } from "@alphasights/alphadesign-components";
import { PrimerCompanyLogo } from "pages/AlphaNowPage/primers/components";
import styles from "./styles/textQuestion.module.css";
import {
  CHART_TYPES,
  OneDimensionalTextResults,
  TransformedSurveyMetadata,
  ZeroDimensionalTextResults,
} from "views/SurveysResultsView/api/types";
import { useSurveyMetadata } from "views/SurveysResultsView/api";
import { useSurveysResultsStore } from "views/SurveysResultsView/state";

export type TextQuestionListProps = {
  chartType:
    | typeof CHART_TYPES.ZERO_DIMENSIONAL_TEXT
    | typeof CHART_TYPES.ONE_DIMENSIONAL_TEXT
    | typeof CHART_TYPES.TWO_DIMENSIONAL_TEXT_NPS;
  results: ZeroDimensionalTextResults | OneDimensionalTextResults;
};

const TextQuestionList = ({ chartType, results }: TextQuestionListProps) => {
  const { color, icons, spacing, typography } = useThemeTokens();

  const hasHeader = chartType !== CHART_TYPES.ZERO_DIMENSIONAL_TEXT;

  const surveyId = useSurveysResultsStore(({ surveyId }) => surveyId);
  const { data: surveyMetadata } = useSurveyMetadata(surveyId);
  const { companyDetails } = surveyMetadata as TransformedSurveyMetadata;

  return (
    <x.div className={styles.topicsContainer} rowGap={spacing.inner.base06}>
      {results.aggregated.map((response, index) => {
        const { answerText: title, openEndedResponses, isVendor } = response;

        if (openEndedResponses.length === 0) return null;
        const logoUrl = companyDetails.find((company) => company.name === title)?.logoUrl;

        return (
          <x.div key={title} className={title ? styles.topicGroup : ""} mt={index === 0 ? 0 : spacing.inner.base06}>
            {hasHeader && (
              <x.div {...typography.body.em} color={color.text.strong._} mb={spacing.inner.base02}>
                <x.span display="inline-flex" alignItems="center" backgroundColor="inherit">
                  {isVendor && <PrimerCompanyLogo size={icons.size.sm} src={logoUrl} />}
                  <Typography variant="body-em" color={color.text.strong._} component="span" ml={spacing.inner.base02}>
                    {title}
                  </Typography>
                </x.span>
              </x.div>
            )}
            <x.ul listStyleType="disc" paddingLeft={spacing.layout.base03}>
              {openEndedResponses.map((response, responseIndex) => {
                return (
                  <x.li
                    key={responseIndex.toString()}
                    {...typography.body.regular}
                    color={color.text.strong._}
                    fontStyle="italic"
                    className={styles.topicGroup}
                  >
                    '{response.text}'
                  </x.li>
                );
              })}
            </x.ul>
          </x.div>
        );
      })}
    </x.div>
  );
};

export default TextQuestionList;
