// Raw Metadata types (/survey/:id/metadata)
export type CompanyDetails = {
  cdsAlphaCompanyId: number;
  name: string;
  logoUrl: string;
};

type SurveyTab = {
  name: string;
  display: string;
};

type SurveyQuestion = {
  id: string;
  label: string;
  subTab: SurveyTab;
  tab: SurveyTab;
  text: string;
  subtitle: string;
};

export type SurveyMetadata = {
  companyDetails: CompanyDetails[];
  id: string;
  decipherId: string;
  projectId: string;
  status: "closed" | "live" | "draft";
  startDate: string | null;
  endDate: string | null;
  type: string;
  name: string;
  questions: SurveyQuestion[];
  tabs: SurveyTab[];
};

// --- Metadata types after the transformation to ease the frontend work ---
export type TransformedQuestion = Pick<SurveyQuestion, "id" | "label" | "text" | "subtitle"> & {
  questionIndex: number;
};
export type TransformedSubTab = SurveyTab & { questions: TransformedQuestion[] };
export type TransformedTab = SurveyTab & { subTabs: TransformedSubTab[] };

export type TransformedSurveyMetadata = {
  companyDetails: CompanyDetails[];
  id: string;
  decipherId: string;
  projectId: string;
  status: "closed" | "live" | "draft";
  startDate: string | null;
  endDate: string | null;
  type: string;
  name: string;
  tabs: TransformedTab[];
  questions: SurveyQuestion[];
};

// Results types (/survey/:id/results/aggregated)
export enum CHART_TYPES {
  ZERO_DIMENSIONAL_TEXT = "zeroDimensionalText",
  ONE_DIMENSIONAL_TEXT = "oneDimensionalText",
  TWO_DIMENSIONAL_TEXT_NPS = "twoDimensionalTextNetPromoterScore",
  ONE_DIMENSIONAL_TEXT_GRID = "oneDimensionalTextGrid",
  ONE_DIMENSIONAL_BAR_CHART = "oneDimensionalBarChart",
  ONE_DIMENSIONAL_BAR_CHART_CATEGORICAL = "oneDimensionalBarChartCategorical",
  ONE_DIMENSIONAL_FLOATING_BAR_CHART_DIVERGING = "oneDimensionalFloatingBarChartDiverging",
  ONE_DIMENSIONAL_FLOATING_BAR_CHART_SEQUENTIAL = "oneDimensionalFloatingBarChartSequential",
  ONE_DIMENSIONAL_STACKED_BAR_CHART_LIKERT = "oneDimensionalStackedBarChartLikert",
  TWO_DIMENSIONAL_STACKED_BAR_CHART = "twoDimensionalStackedBarChart",
  TWO_DIMENSIONAL_STACKED_BAR_CHART_DIVERGING = "twoDimensionalStackedBarChartDiverging",
  TWO_DIMENSIONAL_STACKED_BAR_CHART_LIKERT = "twoDimensionalStackedBarChartLikert",
  TWO_DIMENSIONAL_STACKED_BAR_CHART_NET_PROMOTER_SCORE = "twoDimensionalStackedBarChartNetPromoterScore",
  TWO_DIMENSIONAL_STACKED_BAR_CHART_SEQUENTIAL = "twoDimensionalStackedBarChartSequential",

  THREE_DIMENSIONAL_SCATTER_CHART_KPC = "threeDimensionalScatterChartKpc",
  THREE_DIMENSIONAL_STACKED_BAR_CHART_LIKERT = "threeDimensionalStackedBarChartLikert",
  THREE_DIMENSIONAL_STACKED_BAR_CHART_DIVERGENT = "threeDimensionalStackedBarChartDivergent",
  THREE_DIMENSIONAL_STACKED_BAR_CHART_SEQUENTIAL = "threeDimensionalStackedBarChartSequential",
  THREE_DIMENSIONAL_STACKED_BAR_CHART_CATEGORICAL = "threeDimensionalStackedBarChartCategorical",
}

type OpenEndedResponse = {
  expertId: string;
  text: string;
};

type ZeroDimensionalTextAggregatedResults = {
  answerId: string | null;
  answerLabel: string | null;
  answerText: string | null;
  quantity: number;
  openEndedResponses: OpenEndedResponse[];
  isVendor: boolean;
};

export type ZeroDimensionalTextResults = {
  aggregated: ZeroDimensionalTextAggregatedResults[];
  average: null;
};

export type AggregatedResult = {
  answerId: string;
  answerLabel: string;
  answerText: string;
  quantity: number;
  openEndedResponses: OpenEndedResponse[];
  isVendor: boolean;
};

type FloatingAggregatedResult = AggregatedResult & {
  start: number;
  end: number;
};

export type OneDimensionalTextResults = OneDimensionalBarChartResults;

export type OneDimensionalBarChartResults = {
  aggregated: AggregatedResult[];
  average: {
    category: string;
    value: number | null;
  };
};

export type OneDimensionalFloatingBarChartResults = {
  aggregated: FloatingAggregatedResult[];
  average: {
    category: string | null;
    value: number | null;
  } | null;
};

type TwoDimensionalStackedBarChartAggregatedResults = {
  x: AggregatedResult;
  yElements: AggregatedResult[];
};

export type TwoDimensionalStackedBarChartResults = {
  aggregated: TwoDimensionalStackedBarChartAggregatedResults[];
  average: null;
};

type TwoDimensionalStackedBarChartNPSAggregatedResults = TwoDimensionalStackedBarChartAggregatedResults & {
  netPromoterScore: number;
};

type TwoDimensionalStackedBarChartMedianCategoryAggregatedResults = TwoDimensionalStackedBarChartAggregatedResults & {
  average: {
    category: string | null;
    value: number | null;
  } | null;
};

export type TwoDimensionalStackedBarChartNPSResults = {
  aggregated: TwoDimensionalStackedBarChartNPSAggregatedResults[];
  average: null;
};
export type TwoDimensionalStackedBarChartMedianCategoryResults = {
  aggregated: TwoDimensionalStackedBarChartMedianCategoryAggregatedResults[];
  average: null;
};

export type ThreeDimensionalScatterChartKpcYElement = {
  y: AggregatedResult;
  zElements: AggregatedResult[];
  average: {
    category: string | null;
    value: string | null;
  } | null;
};

export type ThreeDimensionalScatterChartKpcAggregated = {
  x: AggregatedResult;
  yElements: ThreeDimensionalScatterChartKpcYElement[];
};

export type ThreeDimensionalScatterChartKpcAggregatedResults = {
  aggregated: ThreeDimensionalScatterChartKpcAggregated[];
  average: null;
};

export type Results = {
  id: string;
  label: string;
  title: string;
} & (
  | {
      chartType: typeof CHART_TYPES.ZERO_DIMENSIONAL_TEXT;
      results: ZeroDimensionalTextResults;
    }
  | {
      chartType: typeof CHART_TYPES.ONE_DIMENSIONAL_TEXT;
      results: OneDimensionalTextResults;
    }
  | {
      chartType:
        | typeof CHART_TYPES.ONE_DIMENSIONAL_BAR_CHART
        | typeof CHART_TYPES.ONE_DIMENSIONAL_BAR_CHART_CATEGORICAL;
      results: OneDimensionalBarChartResults;
    }
  | {
      chartType:
        | typeof CHART_TYPES.ONE_DIMENSIONAL_FLOATING_BAR_CHART_DIVERGING
        | typeof CHART_TYPES.ONE_DIMENSIONAL_FLOATING_BAR_CHART_SEQUENTIAL;
      results: OneDimensionalFloatingBarChartResults;
    }
  | {
      chartType:
        | typeof CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_NET_PROMOTER_SCORE
        | CHART_TYPES.TWO_DIMENSIONAL_TEXT_NPS;
      results: TwoDimensionalStackedBarChartNPSResults;
    }
  | {
      chartType: typeof CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_DIVERGING;
      results: TwoDimensionalStackedBarChartNPSResults;
    }
  | {
      chartType:
        | typeof CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART
        | typeof CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_LIKERT
        | typeof CHART_TYPES.TWO_DIMENSIONAL_STACKED_BAR_CHART_SEQUENTIAL;
      results: TwoDimensionalStackedBarChartResults;
    }
  | {
      chartType:
        | typeof CHART_TYPES.ONE_DIMENSIONAL_STACKED_BAR_CHART_LIKERT
        | typeof CHART_TYPES.ONE_DIMENSIONAL_TEXT_GRID
        | typeof CHART_TYPES.THREE_DIMENSIONAL_SCATTER_CHART_KPC
        | typeof CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_DIVERGENT
        | typeof CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_SEQUENTIAL
        | typeof CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_CATEGORICAL
        | typeof CHART_TYPES.THREE_DIMENSIONAL_STACKED_BAR_CHART_LIKERT;
      results: any;
    }
);

export type PaginatedAggregatedResults = {
  total: number;
  page: number;
  pageSize: number;
  items: Results[];
};

// Survey results insights (/survey/:id/results/insights)
type SpeakerInsights = {
  id: string;
  label: string;
  text: string;
};

type SpeakerDataResults = {
  tab: string;
  insights: SpeakerInsights[];
};

export type SurveySpeakerData = {
  resultSetId: string;
  advisorData: {
    id: string;
    name: string;
    transId: string;
  };
  summary: {
    vendors: string[];
    spend: string | null;
  };
  results: SpeakerDataResults[];
};

export type SurveyInsights = {
  total: number;
  page: number;
  pageSize: number;
  items: SurveySpeakerData[];
};

export type Survey = {
  total: number;
  page: number;
  pageSize: number;
  items: {
    id: string;
    decipherId: string;
    projectId: number;
    type: string;
    name: string;
    createdAt: string;
    updatedAt: string;
  }[];
};

export type FetchSurveysProps = {
  page?: number;
  pageSize?: number;
  columnToFilter?: string;
  order?: string;
  projectId?: string;
  token?: string;
};
