import { projects as projectsApi, CompanyLogo } from "@alphasights/portal-api-client";
import { useQuery } from "@tanstack/react-query";

export function useRelevantCompanyLogo(projectToken: string, interactionId: string, enabled = true) {
  const isEnabled = !!projectToken && !!interactionId && enabled;

  const query = useQuery<CompanyLogo[]>({
    queryKey: ["relevantCompanyLogo", projectToken],
    queryFn: () => projectsApi.getPrimaryCompaniesLogos(projectToken),
    enabled: isEnabled,
    refetchOnMount: false,
    cacheTime: 1000 * 60 * 5, // 5 minutes
  });
  const data = query.data?.find((logo) => logo.interactionId === interactionId)?.logo;

  return { ...query, data, isLoading: query.isLoading && isEnabled };
}
