import { useState, useEffect } from "react";
import deliverablesService from "./deliverablesService";
import { useCurrentProjectContext } from "providers/CurrentProjectProvider";
import useModal from "hooks/useModal";
import TranscriptActions from "pages/AlphaNowPage/components/AlphaNowTranscript/TranscriptActions";
import { AlphaNowContentType, AlphaNowProductType } from "@alphasights/client-portal-shared";
import { useCurrentUser } from "@alphasights/portal-auth-react";
import { requestExpertContent } from "content/AlphaNow";
import useRequestForProject from "./useRequestForProject";
import { RequestExpertOverlay } from "pages/AlphaNowPage/components";
import { REQUEST_EXPERT_DISCLAIMER } from "views/DeliverablesView/constants";
import PrivateLibrarySettingsModal from "views/DeliverablesView/PrivateLibrarySettingsModal";
import { contentService } from "services/content";
import { LIBRARY_STATUS } from "views/DeliverablesView/PrivateLibrarySettingsModal/PrivateLibrarySettingsModal";
import { isContentAccessible } from "pages/AlphaNowPage/utils/isContentAccessible";

export const ContentCTAs = ({ suggestion, updateDeliverableSuggestion }) => {
  const {
    id,
    libraryStatus,
    contentType,
    productType,
    speakers,
    privateMode,
    purchaseStatus,
    approvalStatus,
    bookmarked,
  } = suggestion;

  const { project } = useCurrentProjectContext();
  const { isVisible: isModalVisible, onOpen: onOpenModal, onClose: onCloseModal } = useModal();
  const [canRequestForProject, setCanRequestForProject] = useState(true);

  const { submit, validSpeakers } = useRequestForProject({ speakers, setCanRequestForProject, project });

  const currentUser = useCurrentUser();

  const [privateLibrarySettingsVisible, setPrivateLibrarySettingsVisible] = useState(false);
  const [privateLibraryStatus, setPrivateLibraryStatus] = useState();

  useEffect(() => {
    if (libraryStatus) {
      setPrivateLibraryStatus(libraryStatus);
    }
  }, [libraryStatus]);

  const onToggleBookmark = async () => {
    try {
      if (bookmarked) {
        await deliverablesService.deleteContentBookmark(project.token, id);
      } else {
        await deliverablesService.postContentBookmark(project.token, id);
      }
      updateDeliverableSuggestion(id, { bookmarked: !bookmarked });
    } catch (error) {
      console.error("Error toggling bookmark:", error);
    }
  };

  const handleSubmit = async (selectedExperts, reason) => {
    await submit(selectedExperts, reason);
    onCloseModal();
  };

  const hasDownloadButton =
    [AlphaNowContentType.srm, AlphaNowContentType.alphaview].includes(contentType) && !project.eligibleForPitch;

  const isPrimer = [
    AlphaNowProductType.companyPrimer,
    AlphaNowProductType.customerPrimer,
    AlphaNowProductType.marketPrimer,
  ].includes(productType);

  const isPTLEnabledClient = project.privateTranscriptContributor;
  const isPrimerAccessible = isContentAccessible(purchaseStatus, approvalStatus);
  const showSettingsButton = isPTLEnabledClient && isPrimer && privateMode && isPrimerAccessible;
  const hasWatchlistButton = currentUser && !currentUser.consultancyClient;

  const handlePrivateLibraryContribution = async (isToggleOn) => {
    const { status } = await contentService.updateLibraryRecord({
      uuid: id,
      resourceType: contentType,
      status: isToggleOn ? LIBRARY_STATUS.active : LIBRARY_STATUS.inactive,
    });
    setPrivateLibraryStatus(status);
    setPrivateLibrarySettingsVisible(false);
  };

  return (
    <>
      <TranscriptActions
        content={suggestion}
        downloadButtonProps={{ hasDownloadButton, projectToken: project.token }}
        bookmarkButtonProps={{ isBookmarked: bookmarked, onToggleBookmark }}
        settingsButtonProps={{
          showSettingsButton,
          onSettingsClick: () => setPrivateLibrarySettingsVisible(true),
        }}
        watchlistButtonProps={{ hasWatchlistButton }}
        requestExpertProps={{
          openRequestExpertOverlay: onOpenModal,
          isRequestExpertButtonDisabled: !canRequestForProject,
          requestExpertButtonText: requestExpertContent,
        }}
      />
      {isModalVisible && (
        <RequestExpertOverlay
          onClose={onCloseModal}
          onCancel={onCloseModal}
          speakers={validSpeakers}
          onSubmit={handleSubmit}
          disclaimer={REQUEST_EXPERT_DISCLAIMER}
        />
      )}

      {privateLibrarySettingsVisible && (
        <PrivateLibrarySettingsModal
          libraryStatus={privateLibraryStatus}
          onClose={() => setPrivateLibrarySettingsVisible(false)}
          onSubmit={handlePrivateLibraryContribution}
        />
      )}
    </>
  );
};
